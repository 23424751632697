import React, { Component } from 'react';
// import { Document } from 'react-pdf'
// import file from '../resume.pdf';

class Resume extends Component {
    render() {
        return (
            <div>
                <h1>Resume </h1>
                {/* <Document file="./resume" /> */}
            </div>
        );
    }
}

export default Resume;
