import React, { Component } from 'react';
import { Cell, Grid, Textfield, Button } from 'react-mdl'
import { Link } from 'react-router-dom'
class Sent extends Component {
    render() {
        return (
            <div className="sent" >
                Thank You
                Your Message Has Been Sent!
                I Will Be In Touch Soon 
                
                <br/>
                <Link to="/"><Button  primary>Home </Button></Link>   
            </div>
        );
    }
}

export default Sent;